<template>
  <div id="reclaim-requests">
    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" title="Reclamations">
      <template v-slot:right-end>
        <div class="vx-row">
        </div>
      </template>
    </breadcrumb-base>


    <!-- Error List State-->
    <transition name="fade">
      <div class="py-6 flex w-full bg-img" v-if="errorFetching">
        <div
          class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
          <img src="@/assets/images/pages/svg_error.svg" :alt="$t('NoData')"
               class="mx-auto mb-4 max-w-full">
          <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('ErrorData') }}</h1>
        </div>
      </div>
    </transition>

    <transition name="fade" v-if="!errorFetching">

      <div class="w-full">
        <!-- Empty List State -->

        <div class="flex flex-row w-full mx-2 space-x-8 w-1/" v-if="reclaimRequestsRaw">
          <div
            class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base">

            <reclaim-card
              :text="reclaimRequestsRaw.pin_status.new"
              :title="$t('New')"
              color="white"
              text-color="white"
              bg-color="danger"
              @tap="fetchNewList('new')"
              type="area"/>
          </div>
          <div
            class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base">
            <reclaim-card
              :text="reclaimRequestsRaw.pin_status.in_progress"
              :title="$t('InProgress')"
              color="white"
              text-color="white"
              bg-color="warning"
              @tap="fetchNewList('in-progress')"
              type="area"/>
          </div>
          <div
            class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base">
            <reclaim-card
              :text="reclaimRequestsRaw.pin_status.sold"
              :title="$t('Sold')"
              color="white"
              text-color="white"
              bg-color="success"
              @tap="fetchNewList('sold')"
              type="area"/>
          </div>
          <!--              <div-->
          <!--                   class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/5 xl:w-1/5 mb-base">-->
          <!--                <reclaim-card-->
          <!--                  :text="reclaimRequestsRaw.pin_status.credit_issued"-->
          <!--                  :title="$t('IssueCreditNote')"-->
          <!--                  color="white"-->
          <!--                  text-color="white"-->
          <!--                  bg-color="primary"-->
          <!--                  @tap="fetchNewList('issue-credit-note')"-->
          <!--                  type="area"/>-->
          <!--              </div>-->
          <!--              <div-->
          <!--                class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/5 xl:w-1/5 mb-base">-->
          <!--                <reclaim-card-->
          <!--                  :text="reclaimRequestsRaw.pin_status.rejected"-->
          <!--                  :title="$t('Rejected')"-->
          <!--                  color="black"-->
          <!--                  text-color="black"-->
          <!--                  bg-color="white"-->
          <!--                  @tap="fetchNewList('reject')"-->
          <!--                  type="area"/>-->
          <!--              </div>-->
        </div>

        <div class="w-full vx-row mx-2 pt-5">
          <div class="md:w-1/4 w-full vs-col px-3">
            <vx-card class=" vs-col px-5">

              <div class=" vs-col py-5">
                <div class="vs-col">

                  <dynamic-customers-drop-down
                    :show-clear="true"
                    :placeholder="$t('SearchCustomer')"
                    :selected-item="selectedCustomer || {}"
                    @on-item-selected="onCustomerFilterChanged">
                  </dynamic-customers-drop-down>
                  <div class="py-2"></div>
                </div>
                <!--                  <dynamic-customers-drop-down-->
                <!--                    class="w-full"-->
                <!--                    :placeholder="$t('SearchCustomer')"-->
                <!--                    @on-item-selected="onCustomerChanged($event)">-->
                <!--                  </dynamic-customers-drop-down>-->

                <div class="w-full" v-if="activeUserInfo.account_role == 0">
                  <label style="font-size: 12px; font-weight: bold">
                    {{ $t('PinBatchSearch') }}
                    <span
                      @click="pinBatchSearch = '';fetchReclaimRequests()"
                      class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
                  </label>
                  <div class="vs-col w-full">
                    <vs-input class="inputx sm:pb-6 w-full"
                              :placeholder="$t('PinBatchSearch')" v-model="pinBatchSearch"/>
                  </div>
                  <div class="py-2"></div>
              </div>
              <!--                  <dynamic-customers-drop-down-->
              <!--                    class="w-full"-->
              <!--                    :placeholder="$t('SearchCustomer')"-->
              <!--                    @on-item-selected="onCustomerChanged($event)">-->
              <!--                  </dynamic-customers-drop-down>-->
              <label style="font-size: 12px; font-weight: bold">
                {{ $t('ReclamationStatus') }}
                <span
                  @click="statusFilter = null;fetchReclaimRequests()"
                  class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
              </label>
              <ul class="leftx my-4">
                <li class="py-2">
                  <vs-radio
                    v-model="statusFilter"
                    @change="fetchReclaimRequests()"
                    vs-name="radios1" vs-value="new">{{ $t('New') }}
                  </vs-radio>
                </li>
                <li class="py-2">
                  <vs-radio
                    v-model="statusFilter"
                    @change="fetchReclaimRequests()"
                    vs-name="radios1" vs-value="in-progress">{{ $t('InProgress') }}
                  </vs-radio>
                </li>
                <li class="py-2">
                  <vs-radio
                    @change="fetchReclaimRequests()"
                    v-model="statusFilter"
                    vs-name="radios1" vs-value="sold">{{ $t('Sold') }}
                  </vs-radio>
                </li>
                <li class="py-2">
                  <vs-radio
                    v-model="statusFilter"
                    @change="fetchReclaimRequests()"
                    vs-name="radios1" vs-value="issue-credit-note">{{ $t('IssueCreditNote') }}
                  </vs-radio>
                </li>
                <li class="py-2">
                  <vs-radio
                    v-model="statusFilter"
                    @change="fetchReclaimRequests()"
                    vs-name="radios1" vs-value="reject">{{ $t('Reject') }}
                  </vs-radio>
                </li>
              </ul>


              <!--            From TO Date Filters -->
              <label style="font-size: 12px; font-weight: bold">{{ $t('FromDate') }}
                <span
                  @click="startDate = null;endDate = null;fetchReclaimRequests()"
                  class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
              </label>
              <datepicker :placeholder="$t('FromDate')"
                          :disabled-dates="{from: new Date()}"
                          v-model="startDate"></datepicker>
              <div class="py-2"></div>
              <label style="font-size: 12px; font-weight: bold">{{ $t('ToDate') }}
                <span
                  @click="startDate = null;endDate = null;fetchReclaimRequests()"
                  class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
              </label>
              <datepicker :placeholder="$t('ToDate')"
                          v-model="endDate" :disabled-dates="disabledDates"></datepicker>
              <div class="py-2"></div>

              <vs-button class="mt-3 w-full py-4 px-3" color="primary"
                         @click="fetchReclaimRequests()">{{ $t('Filter').toUpperCase() }}
              </vs-button>
              <vs-button class="mt-3 w-full py-4 px-3" color="primary"
                         @click="clearFilterData">{{ $t('ClearAllFilters').toUpperCase() }}
              </vs-button>
          </div>
          </vx-card>
        </div>
        <div class="md:w-3/4 w-full vs-col" v-if="reclaimRequests.length === 0">
          <transition name="fade">
            <div class="h-screen flex w-full bg-img" v-if="!errorFetching">
              <div
                class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
<!--                <img src="@/assets/images/pages/svg_blank.svg" :alt="$t('NoData')"-->
<!--                     class="mx-auto mb-4 max-w-full">-->
                <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('NoData') }}</h1>
              </div>
            </div>
          </transition>
        </div>
        <div class="md:w-3/4 w-full vs-col" v-if="reclaimRequests.length >= 0">
          <vs-table-modified v-show="reclaimRequests.length > 0 && !errorFetching" ref="table"
                             :max-items="itemsPerPage"
                             v-on:selected="openReclaimDetails"
                             :data="reclaimRequests">

            <template slot="thead">
              <vs-th>{{ $t('CustomerId') }}</vs-th>
              <vs-th>{{ $t('Customer') }}</vs-th>
              <vs-th>{{ $t('Product') }}</vs-th>
              <vs-th>{{ $t('Status') }}</vs-th>
              <vs-th>{{ $t('Batch') }}</vs-th>
              <vs-th>{{ $t('Pin') }}</vs-th>
              <vs-th>{{ $t('Created') }}</vs-th>
              <vs-th>{{ $t('LastUpdated') }}</vs-th>
            </template>

            <template slot-scope="{data}">
              <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

                <vs-td>
                  <div>
                    <p class="user-name font-medium truncate">{{ tr.customer_id }}</p>
                    <p class="font-medium truncate">{{ tr.organisation_name }}</p>
                  </div>
                </vs-td>
                <vs-td>
                  <div>
                    <p class="font-medium truncate">{{ tr.first_name }} {{ tr.last_name }}</p>
                    <p style="font-size: 11px; ">
                      {{ tr.address }}<span v-if="tr.house_no"> {{ tr.house_no }}</span>
                    </p>

                    <p style="font-size: 11px;">
                      {{ tr.postal }} {{ tr.city }}
                    </p>
                  </div>
                </vs-td>
                <vs-td>
                  <p class="user-name font-medium truncate">{{ tr.product }} -
                    {{ tr.denomination | germanNumberFormat }}</p>
                </vs-td>

                <vs-td>
                  <div class="text-center flex flex-col justify-center">
                    <div class="tooltip">
                      <vs-chip :color="getStatusColor(tr.status)" class="user-status">{{
                          getStatusText(tr.status) | title
                        }}
                      </vs-chip>
                      <span class="tooltiptext tooltip-top"
                            v-if="tr.clam_message">{{ tr.clam_message }}</span>
                    </div>
                  </div>
                </vs-td>
                <vs-td>
                  <p class="user-name font-medium truncate">{{ tr.batch }}</p>
                </vs-td>
                <vs-td>
                  <p class="user-name font-medium truncate">{{ tr.pin }}</p>
                </vs-td>
                <vs-td>
                  <p class="user-name font-medium truncate"
                     style="max-width: 100px;white-space: normal !important;padding: 0 8px;"
                  >{{ tr.created | dateFormat }}</p>
                </vs-td>
                <vs-td>
                  <p class="user-name font-medium truncate"
                     style="max-width: 100px;white-space: normal !important;padding: 0 8px;"
                  >{{ tr.updated | dateFormat }}</p>
                </vs-td>


                <!--                    <vs-td class="whitespace-no-wrap vx-row">-->

                <!--                      <vs-button @click="onUpdatePressed(tr)">{{ $t('Update') }}</vs-button>-->
                <!--                    </vs-td>-->

              </vs-tr>
              </tbody>
            </template>
            <template slot="footer">
            </template>
            <template slot="footer-left">
              <div class="flex">
                <vs-new-pagination :total="vTotalPages" v-model="cPage"
                                   v-on:change="listPageChanged">
                </vs-new-pagination>
              </div>
            </template>
          </vs-table-modified>
        </div>
      </div>
  </div>
  </transition>

  <transition>
    <vx-modal v-if="showUpdateModal" component-class="modal-container-fit">
      <template slot="body">
        <h2 class="text-center">{{ $t('Reclamation') }}</h2>
        <ul class="leftx my-4">
          <li class="py-2">
            <vs-radio
              v-model="popSelectedOption"
              vs-name="radios1" vs-value="new">{{ $t('New') }}
            </vs-radio>
          </li>
          <li class="py-2">
            <vs-radio
              v-model="popSelectedOption"
              vs-name="radios1" vs-value="in-progress">{{ $t('InProgress') }}
            </vs-radio>
          </li>
          <li class="py-2">
            <vs-radio
              v-model="popSelectedOption"
              vs-name="radios1" vs-value="sold">{{ $t('Sold') }}
            </vs-radio>
          </li>
          <li class="py-2">
            <vs-radio
              v-model="popSelectedOption"
              vs-name="radios1" vs-value="issue-credit-note">{{ $t('IssueCreditNote') }}
            </vs-radio>
          </li>
          <li class="py-2">
            <vs-radio
              v-model="popSelectedOption"
              vs-name="radios1" vs-value="reject">{{ $t('Reject') }}
            </vs-radio>
          </li>
        </ul>

        <div class="vs-row flex justify-center">
          <vs-button class="ml-2 mt-3" color="primary" id="print-card"
                     @click="updateRequestStatus()">{{
              $t('Update')
            }}
          </vs-button>
          <vs-button class="ml-4 mt-3" color="danger" @click="onCloseModal()">{{
              $t('Close')
            }}
          </vs-button>
        </div>
      </template>
    </vx-modal>
  </transition>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import moment from 'moment'
import VxModal from "@/layouts/components/custom/VxModal";
import DynamicCustomersDropDown from "@/views/pages/superadmin/customer/DynamicCustomersDropDown";
import BalanceCard from "@/views/components/BalanceCard";
import ReclaimCard from "@/views/components/ReclaimCard";

export default {
  name: 'ReclaimRequests',
  components: {ReclaimCard, BalanceCard, DynamicCustomersDropDown, Datepicker, VxModal},
  data() {
    return {
      cPage: 1,
      statusFilter: 'new',
      popSelectedOption: null,
      showUpdateModal: false,
      selectedRequest: null,
      pinBatchSearch: '',
      itemsPerPage: 20,
      errorFetching: false,
      breadcrumb: [
        {title: 'Home', i18n: 'Home', url: '/'},
        {title: 'Reclamations', i18n: 'Reclamations', active: true},
      ],
      startDate: null,
      endDate: null,
      selectedCustomer: {},
    }
  },
  computed: {
    disabledDates() {
      if (!this.startDate) {
        return {
          from: new Date(),
        }
      }
      return {
        to: this.startDate,
        from: new Date(),
      }
    },
    reclaimRequests() {
      return this.$store.state.reclamation.reclaimRequests
    },
    reclaimRequestsRaw() {
      return this.$store.state.reclamation.reclaimRequestsRaw
    },
    vTotalPages() {
      return this.$store.state.reclamation.vTotalPages
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
  },
  methods: {
    openReclaimDetails(reclaim) {
      // reclamation_id
      this.$router.push({name: 'reclaim-request', params: {reclaimId: reclaim.reclamation_id}})
    },
    onCustomerFilterChanged(c) {
      if (c && c.customer_id) {
        this.selectedCustomer = c
        this.fetchReclaimRequests()
      } else {

        this.selectedCustomer = {}
        this.fetchReclaimRequests()
      }
    },
    onUpdatePressed(data) {
      this.selectedRequest = data
      this.showUpdateModal = true
      this.popSelectedOption = this.getStatus(data.status)
    },
    onCloseModal() {
      this.selectedRequest = null
      this.showUpdateModal = false
      this.popSelectedOption = null
    },
    fetchNewList(status) {
      this.statusFilter = status
      this.fetchReclaimRequests()
    },
    getStatus(status) {
      if (status === 0) return 'new'
      if (status === 1) return 'in-progress'
      if (status === 2) return 'sold'
      if (status === 3) return 'issue-credit-note'
      if (status === 5) return 'reject'
      return ''
    },
    getStatusId(status) {
      if (status === 'new') return 0
      if (status === 'in-progress') return 1
      if (status === 'sold') return 2
      if (status === 'issue-credit-note') return 3
      if (status === 'reject') return 5
      return ''
    },
    getStatusText(status) {
      // 0 - new, 1 - in-progress, 2 - sold, 3 - issue credit note, 5 - reject
      if (status === 0) return this.$t('InReview')
      if (status === 1) return this.$t('InProgress')
      if (status === 2) return this.$t('InProgress')
      if (status === 3) return this.$t('IssueCreditNote')
      if (status === 5) return this.$t('Reject')
      if (status === 6) return this.$t('IssueCreditNote')
      return ''
    },
    getStatusColor(status) {
      // 0 - new, 1 - in-progress, 2 - sold, 3 - issue credit note, 5 - reject
      if (status === 0) return 'danger'
      if (status === 1) return 'warning'
      if (status === 2) return 'success'
      if (status === 3) return 'purple'
      if (status === 5) return 'primary'
      if (status === 6) return 'purple'
      return ''
    },
    listPageChanged(item) {
      this.fetchReclaimRequests(this.cPage - 1)
    },
    clearFilterData() {
      this.statusFilter = null
      this.selectedCustomer = null
      this.startDate = null
      this.endDate = null
      this.pinBatchSearch = '';
      this.fetchReclaimRequests()
    },
    getFilters() {
      let filters = {}
      if (this.statusFilter) {
        filters['pin_status'] = this.getStatusId(this.statusFilter)
      }

      if (this.selectedCustomer && Object.keys(this.selectedCustomer).length > 0 && this.selectedCustomer.customer_id) {
        filters.customer_id = this.selectedCustomer.customer_id
      }

      if (this.pinBatchSearch) {
        filters.batch_or_pin = this.pinBatchSearch
      }

      if (this.startDate) {
        filters.from_date = moment(this.startDate).format('yyyy-MM-DD')
      }
      if (this.endDate) {
        filters.to_date = moment(this.endDate).format('yyyy-MM-DD')
      }
      return filters
    },
    updateRequestStatus() {
      const payload = {
        update_reclamation_pin: JSON.stringify({
          reclamation_id: this.selectedRequest.reclamation_id,
          status: this.getStatusId(this.popSelectedOption)
        }),
      }
      this.$vs.loading()
      this.$store.dispatch('reclamation/fetchReclaimRequests', {payload})
        .then((data) => {
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Success',
            text: "Reclaim Updated",
            iconPack: 'feather',
            icon: 'icon-check-circle',
            color: 'success',
          })
          this.fetchReclaimRequests()
          this.onCloseModal()
        })
        .catch((err) => {
          this.$vs.loading.close()
          let msg = ''
          try {
            msg = err.response.data.message
          } catch (e) {
            msg = err.message
          }
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: msg,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    fetchReclaimRequests(page = '0') {
      const payload = {
        'page': page,
        ...this.getFilters()
      }
      this.$vs.loading()
      this.$store.dispatch('reclamation/fetchReclaimRequests', {payload})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((err) => {
          console.log(err)
          this.$vs.loading.close()
          this.errorFetching = true
          let msg = ''
          try {
            msg = err.response.data.message
          } catch (e) {
            msg = err.message
          }
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: msg,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  },
  mounted() {
    // this.fetchReclaimRequests()
  },
}
</script>

<style scoped lang="scss">
#reclaim-requests {

  .subtitle {
    font-size: 10px;
    color: #9c9c9c;
  }
}
</style>
